export default {
  // 商品管理
  // 商品列表
  goods_list_add: "GoodsAdd",
  goods_list_edit: "GoodsEdit",
  goods_list_del: "goods_list_del",
  goods_list_status: "goods_list_status",
  goods_list_recommend: "goods_list_recommend",
  goods_list_sync_channel: "goods_list_sync_channel",
  goods_list_weight: "goods_list_weight",
  //   商品分类
  goods_cate_add: "CategoryAdd",
  goods_cate_edit: "CategoryEdit",
  goods_cate_status: "goods_cate_status",
  goods_cate_del: "goods_cate_del",

  // 用户管理
  // 用户列表
  member_list_add_white: "member_list_add_white",
  member_list_add_black: "member_list_add_black",
  //   白名单列表
  member_white_list_add: "WhiteAdd",
  member_white_list_edit: "WhiteEdit",
  member_white_list_del: "member_white_list_del",
  member_white_list_add_black: "member_white_list_add_black",
  //   黑名单列表
  member_black_list_add_white: "member_black_list_add_white",
  member_black_list_add: "BlackAdd",
  member_black_list_edit: "BlackEdit",
  member_black_list_del: "member_black_list_del",
  // 订单备注
  member_order_remark_export: "member_order_remark_export",
  // 售后备注
  member_aftersale_remark_export: "member_aftersale_remark_export",
  //   订单管理
  // 订单列表
  order_list_data_import: "order_list_data_import",
  order_list_export: "order_list_export",
  order_list_detail: "OrderDetail",
  order_list_send: "OrderDelivery",
  order_list_cancel: "order_list_cancel",
  order_list_cancel_high: "order_list_cancel_high",
  order_list_bill: "OrderBill",
  order_list_hetong: "OrderContractDetail",
  order_list_notary_phx: "order_list_notary_phx",
  order_list_credit_report: "OrderCreditReport",
  order_list_update: "OrderUpdateGoods",
  order_list_complete: "order_list_complete",
  order_list_ahead_return: "order_list_ahead_return",
  order_fahuo: "order_edit_address",
  order_list_bill_overdue: "order_list_bill_overdue",
  order_list_offline_pay: "order_list_offline_pay",
  order_list_kami: "order_list_kami",
  order_list_detail_update_sale_price: "order_list_detail_update_sale_price",
  order_list_delivery_detail: "order_list_delivery_detail",
  order_list_after_sale_status: "order_list_after_sale_status",
  order_list_device_status: "order_list_device_status",
  order_list_confirm_receive: "order_list_confirm_receive",
  order_store_calc: "order_store_calc",
  // 往期数据--订单列表
  order_history_list_export: "order_history_list_export",
  order_history_list_detail: "OrderHistoryDetail",
  order_history_list_send: "OrderHistoryDelivery",
  order_history_list_cancel: "order_history_list_cancel",
  order_history_list_cancel_high: "order_history_list_cancel_high",
  order_history_list_bill: "OrderHistoryBill",
  order_history_list_hetong: "OrderHistoryContractDetail",
  order_history_list_credit_report: "OrderHistoryCreditReport",
  order_history_list_update: "OrderHistoryUpdateGoods",
  order_history_list_complete: "order_history_list_complete",
  order_history_list_ahead_return: "order_history_list_ahead_return",
  order_history_edit_address: "order_history_edit_address",
  order_history_list_bill_overdue: "order_history_list_bill_overdue",
  order_history_list_offline_pay: "order_history_list_offline_pay",
  order_history_list_kami: "order_history_list_kami",
  order_history_list_detail_update_sale_price: "order_history_list_detail_update_sale_price",
  order_history_list_delivery_detail: "order_history_list_delivery_detail",
  order_history_list_after_sale_status: "order_history_list_after_sale_status",

  //  订单管理-- 账单列表
  order_bill_list_export: "order_bill_list_export",
  order_bill_expired_today_export: "order_bill_expired_today_export",
  //   财务管理
  // 财务流水
  finance_report_list_add: "FinanceReportAdd",
  finance_report_list_export: "finance_report_list_export",
  finance_report_list_edit_amount: "finance_report_list_edit_amount",
  // 账单管理
  finance_count_list_export: "finance_count_list_export",
  // finance_count_list_offline: 'FinanceOfflinePay',
  // finance_count_list_reduce: 'FinanceReducePay',
  finance_count_list_reduce: "finance_count_list_reduce",
  finance_count_list_amount: "finance_count_list_amount",
  finance_count_list_date: "finance_count_list_date",
  finance_count_list_offline: "finance_count_list_offline",
  finance_count_list_handle_more: "finance_count_list_handle_more",
  finance_count_list_lock: "finance_count_list_lock",
  //门店结算管理
  finance_store_settlement_export: "finance_store_settlement_export",
  finance_store_settlement_retry: "finance_store_settlement_retry",
  finance_store_settlement_offline_payment:
    "finance_store_settlement_offline_payment",
  finance_store_settlement_receipt_download:
    "finance_store_settlement_receipt_download",
  //   审核管理
  // 审核列表
  review_list_draw: "review_list_draw",
  review_list_draw_cancel: "review_list_draw_cancel",
  review_list_draw_done: "ReviewDetail",
  review_list_update: "ReviewUpdateGoods",
  review_edit_address: "review_edit_address",
  review_list_detail_update_price_rate: "review_list_detail_update_price_rate",
  review_list_detail_update_cost_price: "review_list_detail_update_cost_price",
  review_list_detail_update_final_grade:
    "review_list_detail_update_final_grade",
  // 审核明细
  review_assign_list_export: "review_assign_list_export",
  review_assign_list_remark: "review_assign_list_remark",
  // 规则配置
  review_rule_status: "review_rule_status",
  review_rule_del: "review_rule_del",
  review_rule_add: "RuleAdd",
  review_rule_edit: "RuleEdit",
  review_rule_detail: "RuleDetail",

  //   售后管理
  // 售后列表
  after_sale_list_detail: "AfterSaleDetail",
  after_sale_list_draw: "after_sale_list_draw",
  after_sale_list_bill: "AfterSaleBill",
  after_sale_list_remark: "AfterSaleRemark",
  after_sale_list_hetong: "after_sale_list_hetong",
  after_sale_list_abandon: "after_sale_list_abandon",
  // 售后明细
  after_sale_assign_export: "after_sale_assign_export",
  after_sale_assign_remark: "after_sale_assign_remark",
  //   商户管理
  // 商户列表
  merchant_list_add: "MerchantAdd",
  merchant_list_edit: "MerchantEdit",
  merchant_list_del: "merchant_list_del",
  merchant_list_status: "merchant_list_status",
  merchant_list_accp: "merchant_list_accp", //钱包
  merchant_list_withdraw: "merchant_list_withdraw", //列表中的提现按钮
  merchant_list_withdraw_done: "merchant_list_withdraw_done", //详情中的提现按钮
  // 提现列表
  merchant_withdraw_list_export: "merchant_withdraw_list_export",
  // 资方管理
  // 资方列表
  capital_list_status: "capital_list_status",
  capital_list_export: "capital_list_export",
  capital_list_add: "capital_list_add",
  capital_list_edit: "capital_list_edit",
  capital_list_recharge: "capital_list_recharge",
  // 动账明细
  capital_bill_list_add: "capital_bill_list_add",
  capital_bill_list_export: "capital_bill_list_export",
  // 订单列表
  capital_order_list_export: "capital_order_list_export",
  capital_order_list_detail: "OrderDetail",
  capital_order_list_bill: "OrderBill",
  capital_order_list_export2: "capital_order_list_export2",
  capital_order_list_detail2: "OrderDetail",
  capital_order_list_bill2: "OrderBill",
  // 资方提现
  capital_withdraw_list_export: "capital_withdraw_list_export",
  capital_withdraw_list_review: "capital_withdraw_list_review",
  //   渠道管理
  // 渠道列表
  channel_list_add: "ChannelAdd",
  channel_list_edit: "ChannelEdit",
  channel_list_del: "channel_list_del",
  channel_list_status: "channel_list_status",
  channel_list_alipay_fund_auth: "channel_list_alipay_fund_auth",
  channel_list_copy: "channel_list_copy",
  channel_list_code: "channel_list_code",
  channel_list_alipay_code: "channel_list_alipay_code",
  channel_list_reset: "channel_list_reset",
  channel_list_config: "channel_list_config",
  channel_list_page: "channel_list_page",
  // 渠道订单
  channel_order_export: "channel_order_export",
  // 渠道统计
  channel_count_export: "channel_count_export",
  //   运营管理
  // 广告设置
  operation_ad_add: "operation_ad_add",
  operation_ad_edit: "operation_ad_edit",
  operation_ad_submit: "operation_ad_submit",
  // 运营文案
  operation_lease_submit: "operation_lease_submit",
  // 协议合同
  operation_hetong_submit: "operation_hetong_submit",
  // 银行卡
  operation_bank_add: "BankCardAdd",
  operation_bank_edit: "BankCardEdit",
  operation_bank_del: "operation_bank_del",
  operation_bank_status: "operation_bank_status",

  //系统设置
  //   字典管理
  dict_list_add: "dictManageAddOrEdit",
  dict_list_edit: "dictManageAddOrEdit",
  dict_list_del: "dict_list_del",
  dict_list_add_value: "DictValueManage",
  dict_value_add_edit: "DictValueAddOrEdit", // 字典值的新增编辑按钮
  //   权限管理
  // 管理员
  user_list_add: "UserAdd",
  user_list_edit: "UserEdit",
  user_list_del: "user_list_del",
  user_list_status: "user_list_status",
  //   角色
  role_list_add: "RoleAdd",
  role_list_edit: "RoleEdit",
  role_list_del: "role_list_del",
  role_list_menu: "role_list_menu",
  //   菜单
  menu_list_add: "MenuAdd",
  menu_list_edit: "MenuEdit",
  menu_list_del: "menu_list_del",
  //   部门
  dept_list_add: "dept_list_add",
  dept_list_edit: "dept_list_edit",
  dept_list_del: "dept_list_del",
  // 岗位
  job_list_add: "JobAdd",
  job_list_edit: "JobEdit",
  job_list_del: "job_list_del",
  /**
   * 门店管理
   */
  // 门店列表
  store_list_add: "StoreAdd",
  store_list_edit: "StoreEdit",
  store_list_del: "store_list_del",
  store_list_copy: "store_list_copy",
  store_list_qrcode: "store_list_qrcode",
  store_list_wuliao: "store_list_wuliao",
  store_list_alipay_material: "store_list_alipay_material",
  store_list_status: "store_list_status",
  store_list_wap_status: "store_list_wap_status",
  store_list_export: "store_list_export",
  store_list_detail: "store_list_detail",
  store_list_grade_config: "store_list_grade_config",
  store_list_combo_config: "store_list_combo_config",
  // 门店商品
  store_goods1_list_add: "StoreGoods1Add",
  store_goods1_list_edit: "StoreGoods1Edit",
  store_goods1_list_del: "store_goods1_list_del",
  store_goods1_list_status: "store_goods1_list_status",
  store_goods2_list_add: "StoreGoods2Add",
  store_goods2_ist_edit: "StoreGoods2Edit",
  store_goods2_list_del: "store_goods2_list_del",
  store_goods2_list_status: "store_goods2_list_status",
  // 门店方案
  store_combo_create: "store_combo_create",
  store_combo_view: "store_combo_view",
  store_combo_edit: "store_combo_edit",
  store_combo_delete: "store_combo_delete",
  store_combo_status_edit: "store_combo_status_edit",
  /**
   * 数据统计
   */
  // 本金回收统计（日）
  stats_funds_day_list_export: "stats_funds_day_list_export",
  // 本金回收统计（月）
  stats_funds_month_list_export: "stats_funds_month_list_export",
};
