import { mapToList } from "./utils";

export const EnumComboType = {
  // 通配方案
  COMMON: 1,
  // 定制方案
  CUSTOM: 2,
};

export const ComboTypeToTextMap = new Map([
  [EnumComboType.COMMON, "通配方案"],
  [EnumComboType.CUSTOM, "定制方案"],
]);

export const ComboTypeToTextMapForReview = new Map([
  [EnumComboType.COMMON, "门店已配方案"],
  [EnumComboType.CUSTOM, "门店未配方案"],
]);

export const ComboTypeList = mapToList(ComboTypeToTextMap);

export const ComboTypeForReviewList = mapToList(ComboTypeToTextMapForReview);

export const EnumComboGrade = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
};

export const ComboGradeToTextMap = new Map([
  [EnumComboGrade.A, "A级"],
  [EnumComboGrade.B, "B级"],
  [EnumComboGrade.C, "C级"],
  [EnumComboGrade.D, "D级"],
]);

export const ComboGradeList = mapToList(ComboGradeToTextMap);

export const EnumComboStatus = {
  // 已停用
  DISABLED: 0,
  // 生效中
  ENABLE: 1,
};

export const ComboStatusToTextMap = new Map([
  [EnumComboStatus.ENABLE, "生效中"],
  [EnumComboStatus.DISABLED, "已停用"],
]);

export const CmoboGradeToColorMap = new Map([
  [EnumComboGrade.A, "#95de64"],
  [EnumComboGrade.B, "#69b1ff"],
  [EnumComboGrade.C, "#ff9c6e"],
  [EnumComboGrade.D, "#ff7875"],
]);

export const ComboStatusList = mapToList(ComboStatusToTextMap);

// 方案线路
export const EnumComboRoute = {
  ROUTE_1: 1,
  ROUTE_2: 2,
};

export const ComboRouteToTextMap = new Map([
  [EnumComboRoute.ROUTE_1, "线路一"],
  [EnumComboRoute.ROUTE_2, "线路二"],
]);

export const ComboRouteList = mapToList(ComboRouteToTextMap);
