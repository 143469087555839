import { mapToList } from "./utils";

export const EnumStoreGrade = {
  S: "S",
  A: "A",
  B: "B",
  C: "C",
  E: "E",
};

export const StoreGradeToTextMap = new Map([
  [EnumStoreGrade.S, "S级"],
  [EnumStoreGrade.A, "A级"],
  [EnumStoreGrade.B, "B级"],
  [EnumStoreGrade.C, "C级"],
  [EnumStoreGrade.E, "E级"],
]);

export const StoreGradeList = mapToList(StoreGradeToTextMap);
