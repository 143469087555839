import { mapToList } from "./utils";

export const enumNotaryStatus = {
  WAITING_FOR_LINK: 1,
  WAITING_FOR_SIGN: 2,
  WAITING_FOR_AUDIT: 3,
  WAITING_FOR_ACCEPET: 4,
  ACCEPETED: 5,
  ISSUED_IN_PROGRESS: 6,
  ISSUED: 7,
  CANCELED: 8,
};

export const notaryStatusToTextMap = new Map([
  [enumNotaryStatus.WAITING_FOR_LINK, "待生成公证链接"],
  [enumNotaryStatus.WAITING_FOR_SIGN, "待客户签署以及视频录制"],
  [enumNotaryStatus.WAITING_FOR_AUDIT, "待人工初审"],
  [enumNotaryStatus.WAITING_FOR_ACCEPET, "待公证处受理"],
  [enumNotaryStatus.ACCEPETED, "公证处已受理"],
  [enumNotaryStatus.ISSUED_IN_PROGRESS, "公证处出证中"],
  [enumNotaryStatus.ISSUED, "已出具公证书"],
  [enumNotaryStatus.CANCELED, "已取消公证流程"],
]);

export const notaryStatus = mapToList(notaryStatusToTextMap);

export const enumNotaryResult = {
  PASSED: 1,
  MODIFY_AND_PASSED: 2,
  CANCEL_AND_RENOTARY: 3,
  CANCEL: 4,
};

export const notaryResultToTextMap = new Map([
  [enumNotaryResult.PASSED, { label: "初审通过", tips: "" }],
  [
    enumNotaryResult.MODIFY_AND_PASSED,
    { label: "修改后提交公证处审核", tips: "身份证照片替换" },
  ],
  [
    enumNotaryResult.CANCEL_AND_RENOTARY,
    { label: "取消申请并重新公证", tips: "视频核验问题或者合同签署有误" },
  ],
  [enumNotaryResult.CANCEL, { label: "取消公证流程", tips: "订单不做公证" }],
]);

export const notaryResult = mapToList(notaryResultToTextMap);
